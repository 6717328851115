import { ValidatorFn, FormControl, AbstractControl, ValidationErrors } from '@angular/forms';

export class FileValidator {
    // static fileTypeValidator(type: string[]) {
    //     return function(control: FormControl) {
    //       // return (control: AbstractControl): { [key: string]: any } | null => {
    //       const file = control.value;
    //       var existValue: boolean = false;
    //       if (file) {
      
    //         var path = file.replace(/^.*[\\\/]/, "");
      
    //         const extension = path.split(".")[1].toUpperCase();
    //         for (var i = 0; i < type.length; i++) {
    //           let typeFile = type[i].toUpperCase();
    //           if (typeFile === extension.toUpperCase()) {
    //             existValue = true;
    //           }
    //         }
    //         if (existValue == true) {
    //           return null;
    //         } else {
    //           return {
    //               fileTypeValidator: true
    //           };
    //         }
    //       }
    //       return null;
    //     };
    // }

    static fileSizeValidator(maxfilesize: number, file: File | null): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
        if (file) {
          const fileSizeInKB = Math.round(file.size / 1024);
          if (fileSizeInKB > maxfilesize) {
            return { fileSizeValidator: true };
          }
        }
        return null;
      };
    }


  static aspectRatioValidator(file:File): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!file) {
        return null; // Return null if there's no file
      }
  
      const img = new Image();
      const reader = new FileReader();
      
      return new Promise((resolve) => {
        reader.onload = (e) => {
          img.src = e.target!.result as string;
          img.onload = () => {
            const width = img.width;
            const height = img.height;
            
            if (width !== height) {
              resolve({ aspectRatioValidator: true }); // Invalid aspect ratio
            } else {
              resolve(null); // Valid aspect ratio
            }
          };
        };
        reader.readAsDataURL(file);
      });
    };
  }

  static addTimestamp = (fileName: string) => {
    const currentTimestamp = new Date().getTime();
    const nameWithoutExtension = fileName.split('.').slice(0, -1).join('.');
    const extension = fileName.split('.').pop();
    return `${nameWithoutExtension}_${currentTimestamp}.${extension}`;
  };

  static strictEmailValidator(control: AbstractControl): ValidationErrors | null {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; 
    const isValid = emailRegex.test(control.value);
    return isValid ? null : { invalidEmail: true };
  }
}
<app-sidebar>
    <div class="content-wrapper">

           <div class="row">
            <div class="col-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-header card-title bg-primary rounded-top text-white text-center  mb-0">{{title}} User</div>
                    <div class="card-body">
                        
                        <form [formGroup]="userForm" (ngSubmit)="saveUser()" class="forms-sample">
                            <div class="row">
                                <div class="form-group col-lg-6">
                                    <label for="name" class="required-field">User Name</label>
                                    <input placeholder="Enter a name" type="text" class="form-control form-control-sm" formControlName="name" required [readonly]="isReadonly">
                                    <div *ngIf="true" class="invalid-feedback">
                                        Please enter a user name.
                                    </div>
                                </div>

                                <div class="form-group col-lg-6">
                                    <label for="email" class="required-field">User Email</label>
                                    <input placeholder="Enter an email" type="email" class="form-control form-control-sm" formControlName="email" required [readonly]="isReadonly">
                                    <div
                                    *ngIf="userForm.get('email')?.invalid && (userForm.get('email')?.dirty || userForm.get('email')?.touched)"
                                    class="text-danger">
                                    <!-- <div *ngIf="userForm.get('email')?.errors?.required">Email is required.</div> -->
                                    <div *ngIf="userForm.controls['email']?.hasError('invalidEmail')">Invalid email format.</div>
                                  </div>
                                </div>
                                                      

                                <div class="form-group col-lg-6">
                                    <label for="roleId" class="required-field">Select User Role</label>
                                    <select class="form-control form-control-sm  form-select" formControlName="roleId" [attr.disabled]="isReadonly ? '' :null"  required>
                                        <option value="" selected disabled>Choose Role...</option>
                                        <option *ngFor="let role of roles" [value]="role.UT_id">{{role.UT_title}}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="text-center">
                                <button type="submit"  *ngIf="!isReadonly" class="btn btn-primary mr-2">Save</button>
                                <button class="btn btn-light mr-2" routerLink="/admins/portal-users" >Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="staticBackdrop">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title fs-5" id="staticBackdropLabel">To Set User Password</h4>
                    </div>
                    <div class="modal-body">
                        <p>Please inform the user to check the given email and set a new password within 24 hours to complete the user registration process.</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" (click)="hideModal()">Okay</button>
                    </div>
                </div>
            </div>
        </div>



        


    </div>
</app-sidebar>